<template>
    <!-- Page: pages/recent-news -->

    <br/>

    <breadcrumbs :items="path"/>

    <div class="container">
        <alert/>
    </div>

    <hr/>


    <div class="container">
        <userTools/>
    </div>

    <hr/>
    <hr/>

    <div class="container">
        <span v-if="url && name">← </span><a :href="url" v-if="url && name">Back to {{ name }}</a>

        <h1>Recent News <span v-if="name">for {{ name }}</span></h1>

        <div class="column">

            <preloader v-if="loading"></preloader>

            <recent-news-block v-if="!loading && rss" :items="rss.list"/>

            <pagination v-if="!loading && rss.pageCount" v-on:paginated="paginate($event)" :current="page" :pageCount="rss.pageCount"/>

        </div>
    </div>
    <!-- End Page: pages/recent-news -->
</template>

<script>
    import { computed } from "vue";
    import RequestHandler from "@/handler/RequestHandler";
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "@/alivue/components/breadcrumbs";
    import GeneralContainer from "./general-container";
    import UserTools from "@/alivue/components/user-tools";
    import RecentNewsBlock from "./recent-news-block";
    import SearchBlock from "./search-block";
    import { useStore } from "vuex";
    import Preloader from "./preloader";
    import Pagination from "./pagination.vue";

    export default {
        components: {
            Alert,
            Breadcrumbs,
            GeneralContainer,
            UserTools,
            RecentNewsBlock,
            SearchBlock,
            Preloader,
            Pagination,
        },
        data() {
            return {
                rss: [],
                loading: true,
                queryContentId: null,
                name: null,
                path: [],
                url: null,
                limit: 10,
                offset: 0,
                page: 1,
            };
        },
        methods: {
            paginate(pageTo, force) {
                window.scrollTo(0, 0);
                this.page = pageTo;
                this.offset = (pageTo - 1) * this.limit;
                this.loadRSS();
            },
            loadRSS() {
                this.loading = true;
                RequestHandler.loadRSSFeed(this.queryContentId, this.limit, this.offset).then(response => {
                    if (!response) {
                        return;
                    }
                    this.rss = response.rss;
                    this.name = response.name;
                    this.url = response.url;
                    let title = 'Recent News';
                    if (this.name) {
                        title += ' for ' + this.name;
                    }
                    if (this.path.length === 0) {
                        this.path.push({title: title});
                    }

                    this.loading = false;
                }).catch((error) => {
                    console.error(error);
                });
            }
        },
        mounted() {
            this.path = [];

            this.queryContentId = this.$route.query.id;
            if (!this.queryContentId) {
                return;
            }
            this.loadRSS();
        },
        setup() {
            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            return {
                showSearch,
            };
        }
    }
</script>

<style lang="scss">
    thead th {
        min-width: 100px;
    }

    th.sortable {
        cursor: pointer;
    }

    th.sorted {
        font-weight: bold;
    }
</style>
